/*
 * @Description:
 * @Author: laicheng
 * @Date: 2022-06-23 18:10:30
 */
import router from '@/router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

document.title = '美今管家'
NProgress.configure({
  minimum: 0.1,
  ease: 'ease-in-out',
  showSpinner: false
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.level < from.meta.level) {
    to.meta.transitionName = 'slide-left'
  } else {
    to.meta.transitionName = 'slide-right'
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})
