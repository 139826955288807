<!--
 * @Description:
 * @Author: laicheng
 * @Date: 2022-06-23 10:44:00
-->
<template>
  <div>
    <router-view></router-view>

    <!-- <div style="height:200px;width:200px;display:block;background:red">
      <wx-open-launch-weapp id="launch-btn" username="gh_5bac9e9f9a1a" path="pages/item/item?id=26">
        <div v-is="'script'" type="text/wxtag-template">
          <button class="btn" style="height:300px;width:300px;background:yellow">打开小程序</button>
        </div>
      </wx-open-launch-weapp>
    </div> -->
  </div>

</template>
<script setup>
import { onMounted } from 'vue'
import { fetchTicket } from './API/common'
import wx from 'weixin-js-sdk'
onMounted(async () => {
  const result = await fetchTicket({ url: location.href })
  wx.config({
    debug: false,
    appId: result.appId,
    timestamp: result.timestamp,
    nonceStr: result.nonceStr,
    signature: result.signature,
    openTagList: [
      'wx-open-launch-weapp'
    ]
  })
})
</script>
<style>
/* span,
div,
p {
  font-size: 24px;
} */
</style>
