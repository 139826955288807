/*
 * @Description:
 * @Author: laicheng
 * @Date: 2022-06-23 10:44:00
 */
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页'
    },
    component: Home
  },
  {
    path: '/web',
    name: 'Web',
    meta: {
      title: '美今管家'
    },
    component: () => import('../views/web/Index.vue')
  },
  {
    path: '/newcomer',
    name: 'Newcomer',
    meta: {
      title: '新人专享'
    },
    component: () => import('../views/newcomer/Index.vue')
  },
  {
    path: '/combo',
    name: 'Combo',
    meta: {
      title: '组合套餐'
    },
    component: () => import('../views/combo/Index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
