/*
 * @Description:
 * @Author: laicheng
 * @Date: 2022-06-23 13:43:45
 */
import axios from 'axios'

const service = axios.create({
  // baseURL: process.env.NODE_ENV==='development'?'/api':'http://api.v3.meijinguanjia.com/api',
  baseURL: '/api',
  timeout: 50000
})
service.interceptors.request.use(
  (config) => {
    config.headers = {
      token: 'getToken()'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res?.code && res?.code !== 0) {
      return Promise.reject(res.msg || 'error')
    } else {
      return res
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
